import { Accordion } from '@/components/Accordion';
import { componentTheme } from '@/styles/Navigation';
import mergeProps from 'lib/utils/mergeProps';
import { Navigation } from '../Navigation';
import { NavigationLinkProps } from '../Navigation.Link';
import { NavigationMenuProps } from '../Navigation.Menu';

type DrawerMenuLink = NavigationLinkProps | DrawerMenuLink[];

type DrawerMenuLinks = { links: DrawerMenuLink[]; depth?: number; options?: NavigationLinkProps['options'] };

const getNavigationDrawerMenuLinks = ({ links, depth = 1, options }: DrawerMenuLinks) => {
  return links.map((item, index) => {
    if (Array.isArray(item)) {
      const [title, ...links] = item;
      return (
        <Accordion
          key={index}
          title={
            <Navigation.Link
              data-depth={depth}
              {...mergeProps(
                {
                  options: {
                    colors: 'drawer',
                    size: 'drawer',
                    variant: 'drawerPrimary',
                    ...options,
                  },
                },
                title,
              )}
            />
          }
          content={getNavigationDrawerMenuLinks({
            links,
            depth: depth + 1,
            options,
          })}
          options={{ theme: componentTheme('accordion') }}
        />
      );
    }

    return (
      <Navigation.Link
        key={index}
        data-depth={depth}
        {...mergeProps(
          {
            options: {
              colors: 'drawer',
              size: 'drawer',
              variant: depth === 1 ? 'drawerPrimary' : 'drawerSecondary',
              ...options,
            },
          },
          item,
        )}
      />
    );
  });
};

type DrawerMenuGroup = NavigationMenuProps & { links: DrawerMenuLink[] };

type DrawerMenu = {
  toggle?: React.ReactNode;
  menu: DrawerMenuGroup[];
  options?: {
    $menu?: NavigationMenuProps;
    $link?: NavigationLinkProps['options'];
  };
};

export const getNavigationDrawerMenu = ({ toggle, menu, options }: DrawerMenu) => {
  return {
    toggle: toggle ?? <Navigation.Drawer.Toggle />,
    menu: menu?.map(({ links, ...menu }, index) => (
      <Navigation.Menu key={index} size="drawer" variant="drawer" {...options?.$menu} {...menu}>
        {getNavigationDrawerMenuLinks({ links, options: options?.$link })}
      </Navigation.Menu>
    )),
  };
};
