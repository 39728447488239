/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { AllasStandaloneNavigation as ResolvedStandaloneNavigation } from 'sites/allas/components/standalone/Navigation';

export const Navigation = ResolvedStandaloneNavigation;

export type NavigationProps = PropsFromComponent<typeof Navigation>;

export const NavigationWith = (extras: DynamicStandaloneExtras): typeof Navigation => {
    return function Navigation(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Navigation');
        return <ResolvedStandaloneNavigation {...mergeProps({ options: { theme } }, props)} />;
    }
}